import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Breadcrumb, Input, Row, Space } from 'antd';
import Search from 'antd/lib/input/Search';
import * as AWS from 'aws-sdk';
import { ListObjectsOutput, ObjectList } from 'aws-sdk/clients/s3';
import { ChangeEventHandler, useEffect, useState } from 'react';
import PackageTable from '../components/PackageTable';
import * as RexpondoPackage from '../models/RexpondoPackage';
import settings from '../settings.json';

const PackageBrowser = () => {

    const [packageList, setPackageList] = useState<Map<string, RexpondoPackage.Package>>(new Map());

    const [searchedValue, setSearchedValue] = useState<string>();

    useEffect(() => {
        AWS.config.update({
            accessKeyId: settings.credentials.accessKeyId, 
            secretAccessKey: settings.credentials.secretAccessKey, 
            region: settings.credentials.region
        });

        let s3 = new AWS.S3({
            apiVersion: '2006-03-01'
        });
        var params = {
            Bucket: settings.s3.bucketName
        };
        s3.listObjects(params, function (err, data: ListObjectsOutput) {
            if (err) console.log(err, err.stack); // an error occurred
            else {
                setPackageList(RexpondoPackage.convertS3ObjectListToRexpondoPackages(data.Contents!));
            }
        });
    }, [])

    const search = (e: React.FormEvent<HTMLInputElement>) => {
        setSearchedValue(e.currentTarget.value);
    }

    return (
        <div className="PackageBrowser">
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Package Browser</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ display: "flex", gap: "8px", flexDirection: "column" }}>
                <Row>
                    <Input size="large" 
                    placeholder="Inizia a digitare per cercare per nome pacchetto" 
                    prefix={<SearchOutlined />} onChange={search} />
                </Row>
                <Row>
                    <PackageTable packageList={packageList} searchedValue={searchedValue}></PackageTable>
                </Row>
            </div>
        </div>
    );
}

export default PackageBrowser;