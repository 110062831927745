import { Breadcrumb, Layout, Menu, Typography } from 'antd';
import { Content, Header } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import React from 'react';

import './App.less';
import PackageBrowser from './pages/PackageBrowser';
import { Outlet } from "react-router-dom";


function App() {
  return (
    <Layout>
      <Header className="header">
        <div className="logo" />
      </Header>
      <Layout style={{ padding: '0 24px 24px' }}>
        <Content
          className="site-layout-background"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <Outlet></Outlet>
        </Content>
      </Layout>
    </Layout>
  );
}


export default App;
