import { Table, Typography } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import React from 'react';
import { Package, PackageVersion } from '../models/RexpondoPackage';
import { compareBuild } from 'semver';
import dayjs from 'dayjs';
import settings from '../settings.json';

const columns: ColumnsType<PackageVersion> = [
  {
    key: 'version',
    dataIndex: 'version',
    title: 'Versione',
    width: '5%',
    defaultSortOrder: "descend",
    sorter: (a, b) => compareBuild(a.version, b.version)
  },
  {
    key: 'lastModified',
    dataIndex: 'lastModified',
    title: 'Ultima modifica',
    width: '5%',
    sorter: (a, b) => a.lastModified.getTime() - b.lastModified.getTime(),
    render: (text, rexpondoPackage) =>  dayjs(rexpondoPackage.lastModified).format('YYYY-MM-DD, HH:mm:ss')
  },
  {
    key: 'size',
    dataIndex: 'size',
    title: 'Dimensione',
    width: '5%',
    sorter: (a, b) => a.size - b.size,
    render: (text, rexpondoPackage) => rexpondoPackage.humanReadableSize
  },
  {
    key: 'Download',
    width: '5%',
    render: (text, rexpondoPackage) => <Typography.Link href={`${settings.s3.bucketUrl}/${rexpondoPackage.s3Object.Key}`}>Download</Typography.Link>
  },
];

export default function VersionTable({ versionList }: { versionList: PackageVersion[] }) {
  return (
    <div style={{ height: 400, width: '100%' }}>
      <Table columns={columns} dataSource={versionList} />
    </div>
  );
};