import { Breadcrumb, Typography } from 'antd';
import * as AWS from 'aws-sdk';
import { ListObjectsOutput, ListObjectsRequest, ObjectList } from 'aws-sdk/clients/s3';
import { useEffect, useState } from 'react';
import { Link, useLoaderData, useParams } from 'react-router-dom';
import VersionTable from '../components/VersionTable';
import * as RexpondoPackage from '../models/RexpondoPackage';
import settings from '../settings.json';

type Params = {
    packageName: string
}

export function loader({ params }: { params: unknown }) {
    return params as Params;
}

const VersionBrowser = () => {
    const [packageList, setPackageList] = useState<RexpondoPackage.PackageVersion[]>([]);
    const [validName, setValidName] = useState<boolean>(true);

    const urlParams = useLoaderData() as Params;
    useEffect(() => {
        document.title = `${urlParams.packageName} - Rexpondo Package Browser`;
        if (!validName) return;
        AWS.config.update({
            accessKeyId: settings.credentials.accessKeyId,
            secretAccessKey: settings.credentials.secretAccessKey,
            region: settings.credentials.region
        });

        let s3 = new AWS.S3({
            apiVersion: '2006-03-01'
        });
        const params: ListObjectsRequest = {
            Bucket: settings.s3.bucketName,
            Prefix: urlParams.packageName
        };
        s3.listObjects(params, function (err, data: ListObjectsOutput) {
            if (err) console.log(err, err.stack); // an error occurred
            else {
                if (!data.Contents?.length) {
                    setValidName(false);
                    return;
                }
                setPackageList(data.Contents!.map((packageVersion: Object) => RexpondoPackage.PackageVersion.fromS3Object(packageVersion)));
            }
        });
    }, []);

    return (
        <div className="VersionBrowser">
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item><Link to="/packages">Package Browser</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{urlParams.packageName}</Breadcrumb.Item>
            </Breadcrumb>
            { validName &&
                <VersionTable versionList={packageList} />
            } 
            { !validName &&
                <Typography.Title level={2}>Pacchetto {urlParams.packageName} non trovato.</Typography.Title>
            }

        </div>
    );
}

export default VersionBrowser;