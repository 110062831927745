import { Table, Typography } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import React from 'react';
import { Package, PackageVersion } from '../models/RexpondoPackage';
import { compareBuild } from 'semver';
import { Link } from 'react-router-dom';
import settings from '../settings.json';

const columns: ColumnsType<Package> = [
  {
    key: 'name',
    dataIndex: 'name',
    title: 'Nome',
    width: '10%',
    defaultSortOrder: "ascend",
    sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  },
  {
    key: 'latestVersion',
    title: 'Ultima Versione',
    width: '5%',
    sorter: (a, b) => compareBuild(a.latestVersion.version, b.latestVersion.version),
    render: (text, rexpondoPackage) => rexpondoPackage.latestVersion.version
  },
  {
    key: 'size',
    title: 'Dimensione',
    width: '5%',
    sorter: (a, b) => a.latestVersion.size - b.latestVersion.size,
    render: (text, rexpondoPackage) => rexpondoPackage.latestVersion.humanReadableSize
  },
  {
    key: 'allVersions',
    width: '5%',
    render: (text, rexpondoPackage) => <Link to={`${rexpondoPackage.name}`}>Tutte le versioni</Link>
  },
  {
    key: 'download',
    width: '5%',
    render: (text, rexpondoPackage) => <Typography.Link href={`${settings.s3.bucketUrl}/${rexpondoPackage.latestVersion.s3Object.Key}`}>Download</Typography.Link>
  },

];

export default function PackageTable({ packageList, searchedValue }: { packageList: Map<string, Package>, searchedValue?: string }) {
  let packages: Package[];
  if (searchedValue) {
    const filteredPackagesName: string[] = Array.from(packageList.keys()).filter(key => key.toLowerCase().includes(searchedValue.toLowerCase()));
    packages = filteredPackagesName.map(packageName => packageList.get(packageName) as Package);
  } else {
    packages = [...packageList.values()];
  }

  return (
    <div style={{ height: 400, width: '100%' }}>
      <Table columns={columns} dataSource={packages} pagination={{ pageSize: 15 }} />
    </div>
  );
};