import { Object, ObjectList } from "aws-sdk/clients/s3";
import * as semver from 'semver';

/**
 * Descrittore di un pacchetto Rexpondo.
 * Contiene solo le informazioni comuni a tutti i pacchetti recuperati da S3 corrispondenti allo stesso progetto
 */
export class Package {
    
    constructor(
        public name: string,
        public latestVersion: PackageVersion,
    ){}

    // Si chiama add ma in realtà salvo solo l'ultima versione. A questo livello non serve avere lo storico e occuperebbe memoria inutilmente.
    // Se in futuro dovesse servire, basta salvarsi packageVersion in un array
    addPackageVersion(packageVersion: PackageVersion){
        if(!semver.valid(packageVersion.version))  return; //Probaiblmente non è un package Rexpondo o è fatto male
    
        if(!this.latestVersion || semver.gt(packageVersion.version, this.latestVersion.version)) {
            this.latestVersion = packageVersion;
        }
    }
    
    /**
    * Genero un Package a partire da un PackageVersion
    * Questo è utile per inizializzare un nuovo Package senza essere troppo discorsivi. Viene usato solo quando viene trovata una qualsiasi versione di un pacchetto non ancora censito.
    */
    static fromPackageVersion(packageVersion: PackageVersion): Package{
        return new Package(
            packageVersion.name,
            packageVersion,
        );
    }
};

/**
* Descrive la versione di un Pacchetto. Al contrario di quanto si possa pensare, qua sono contenute le reali informazioni riguardo il file recuperato da S3.
* Un file su S3 = un PackageVersion
*/
export class PackageVersion {
    
    constructor(
        public name: string,
        public version: string,
        public lastModified: Date,
        public size: number,
        public humanReadableSize: string,
        public s3Object: Object
    ) {}

    static fromS3Object(object: Object) : PackageVersion{
        const fileName: string = object.Key!;
        const versionPosition = fileName.search(/-[0-9\.]*opm/);
        return new PackageVersion(
            fileName.substring(0,versionPosition),
            fileName.substring(versionPosition + 1, fileName.search(/\.opm/)),
            object.LastModified!,
            object.Size!,
            formatBytes(object.Size!),
            object
        )
    }
};

export const convertS3ObjectListToRexpondoPackages = (objectList: ObjectList) :  Map<string, Package> => {
    const versions : PackageVersion[] = objectList.map((objectList) : PackageVersion => PackageVersion.fromS3Object(objectList));
    const packageList : Map<string, Package> = new Map();
    versions.map((packageVersion: PackageVersion) => {
        if(!packageVersion.name || !packageVersion.version) return;
        if(!packageList.has(packageVersion.name)) {
            packageList.set(packageVersion.name, Package.fromPackageVersion(packageVersion));
        }
        packageList.get(packageVersion.name)!.addPackageVersion(packageVersion);
    })
    return packageList;
}

function formatBytes(bytes: number, decimals: number=2) {
    if(bytes == 0) return '0 Bytes';
    var k = 1024,
        dm = decimals,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
 }